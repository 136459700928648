<script setup lang="ts">
import type { LocationQueryValue } from 'vue-router';
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiClosestAvailableCampsitesProps {
  accoType?: LocationQueryValue | LocationQueryValue[] | undefined;
  adults?: LocationQueryValue | LocationQueryValue[] | number;
  breakpoints?: any;
  campsiteSlug: LocationQueryValue | LocationQueryValue[];
  children?: LocationQueryValue | LocationQueryValue[];
  from: LocationQueryValue | LocationQueryValue[];
  layout?: 'default' | 'small';
  size?: number | null;
  until: LocationQueryValue | LocationQueryValue[];
  zoneId?: string;
  useContainer: boolean;
}
const props = withDefaults(defineProps<CiClosestAvailableCampsitesProps>(), {
  accoType: undefined,
  adults: 2,
  breakpoints: null,
  children: () => [],
  layout: 'default',
  size: null,
  zoneId: 'availabilities',
  useContainer: true,
});

/********************
 * COMPOSITIONS      *
 ********************/
const campsiteStore = useCampsiteStore();
const { $gettext } = useGettext();
const { locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const i18nClosestAvailableCampsitesHeadline = computed(() => {
  return $gettext('Available campsites in the selected travel period');
});

const sliderSettings = computed(() => {
  if (props.layout === 'small') {
    return {
      size: 2,
      breakpoints: {
        767: {
          perView: 1.5,
        },
        480: {
          perView: 1.25,
        },
        400: {
          perView: 1.1,
        },
      },
    };
  }
  return {
    size: 3,
    breakpoints: null,
  };
});

const normalizedAdults = computed(() => {
  return parseInt(props.adults, 10) || 2;
});

const normalizedChildren = computed(() => {
  if (!props.children) {
    return '';
  }
  if (typeof props.children === 'string' || props.children instanceof String) {
    return props.children;
  }
  return props.children.join(',');
});

const limit = computed(() => {
  if (props.layout === 'small') {
    return 6;
  }
  return 12;
});

/********************
 * INITIALIZATION    *
 ********************/
const { data, pending } = await useLazyAsyncData(`closes-${props.campsiteSlug}`, async () => {
  if (props.from && props.until) {
    const response = await campsiteStore.getClosestAvailableCampsites({
      slug: props.campsiteSlug,
      lang: locale.value,
      query: {
        from: props.from,
        until: props.until,
        adults: normalizedAdults.value,
        children: normalizedChildren.value,
        acco_type: props.accoType,
        limit: limit.value,
      },
    });
    return {
      campsites: response?.results,
    };
  }
  return {
    campsites: [],
  };
});
</script>

<template>
  <!-- overflow-x-hidden lg:overflow-auto -->
  <CiPromoSlider
    v-if="!pending"
    :campsites="data?.campsites"
    :size="sliderSettings.size"
    :use-container="props.useContainer"
  >
    <!-- headline -->
    <template #headline>
      <h4
        class="m-0 mb-2 text-center text-base text-black"
        v-html="i18nClosestAvailableCampsitesHeadline"
      />
    </template>

    <!-- slides -->
    <template #slide="{ campsite, index }">
      <CiPromoTile
        :campsite="campsite"
        layout="closest-available-campsites"
        :snowplow-context="{
          bannerId: 'ClosestAvailableCampsitesSlider',
          campaignId: 'closest',
          zoneId: props.zoneId,
          position: index + 1,
        }"
        :availability-params="{
          from: props.from,
          until: props.until,
          adults: props.adults,
          children: props.children,
          acco_type: props.accoType,
        }"
      />
    </template>
  </CiPromoSlider>
</template>

<style></style>
